import { useNavigate, useParams } from "react-router-dom";
import { useState, React, useEffect } from "react";
import swal from "sweetalert";
import Cookies from "universal-cookie";
import { supabase } from "./Red";
import { jsx } from "react/jsx-runtime";

const cookies = new Cookies();

const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return new Date(dateString).toLocaleDateString("ru-RU", options);
};

function Disciplines() {
  const [edit, setEdit] = useState(false);
  const [gameResults, setGameResults] = useState([]);
  const [stadiums, setStadiums] = useState([]);
  const [athletes, setAthletes] = useState([]);
  const [searchGame, setSearchGame] = useState("");
  const [games, setGames] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disciplines, setDisciplines] = useState([]);
  const isLogged = cookies.get("logged") === true;
  const disciplin = useParams();

  const [editGame, setEditGame] = useState({
    game_date: "",
    stadium: { id: "" },
    teams: [{ teamId: "", athletes: [{ id: "", name: "" }] }],
  });

  const [newGame, setNewGame] = useState({
    game_date: "",
    stadium_id: "",
    teams: [{ teamId: 1, athletes: [{ id: "", name: "" }] }],
  });

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);

        const { data: stadiumData, error: stadiumError } = await supabase
          .from("Stadiums")
          .select("id, name");
        if (stadiumError) console.error(stadiumError);
        setStadiums(stadiumData || []);

        const { data: disciplineData, error: disciplineError } = await supabase
          .from("Disciplines")
          .select("id, name");
        if (disciplineError) console.error(disciplineError);
        setDisciplines(disciplineData || []);

        const { data: athletesData, error: athletesError } = await supabase
          .from("Athletes")
          .select("id, name");
        if (athletesError) console.error(athletesError);
        setAthletes(athletesData || []);

        const { data: gamesData, error: gamesError } = await supabase.from(
          "Games"
        ).select(`
            id,
            discipline:Disciplines(id, name),
            game_date,
            stadium:Stadiums(id, name)
          `);
        if (gamesError) console.error(gamesError);
        setGames(gamesData || []);

        const { data: participantsData, error: participantsError } =
          await supabase.from("Participants_of_the_game").select(`
            id,
            game_id,
            athlet:Athletes(id, name),
            team_id
          `);
        if (participantsError) console.error(participantsError);
        setParticipants(participantsData || []);

        const { data: gameresData, error: gameresError } = await supabase
          .from("Game_Results")
          .select("id, game_id, athlet_id, winner");
        if (gameresError) console.error(gameresError);
        setGameResults(gameresData || []);
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, []);

  const handleSaveEdit = async () => {
    try {
      const currentYear = new Date().getFullYear();
      const gameDateYear = new Date(editGame.game_date).getFullYear();
      if (gameDateYear < currentYear || gameDateYear > currentYear + 20) {
        swal("Ошибка", `Дата должны быть между ${currentYear} и ${currentYear+20}`, "error");
        return;
      } 
      
      const { error: gameError } = await supabase
        .from("Games")
        .update({
          game_date: editGame.game_date,
          stadium_id: editGame.stadium.id,
        })
        .eq("id", editGame.id);

      if (gameError) {
        console.log(gameError);
        swal("Ошибка", "Не удалось сохранить изменения", "error");
        return;
      }

      const { error: deleteError } = await supabase
        .from("Participants_of_the_game")
        .delete()
        .eq("game_id", editGame.id);

      if (deleteError) {
        console.error(deleteError);
        swal("Ошибка", "Не удалось удалить старых участников игры", "error");
        return;
      }

      const { data: pid } = await supabase
        .from("Participants_of_the_game")
        .select("id")
        .order("id", { ascending: false })
        .limit(1);

      let participantId = pid.length > 0 ? pid[0].id + 1 : 1;

      for (const team of editGame.teams) {
        for (const athlete of team.athletes) {
          const { error: insertError } = await supabase
            .from("Participants_of_the_game")
            .insert([
              {
                id: participantId,
                game_id: editGame.id,
                athlet_id: athlete.id,
                team_id: team.teamId,
              },
            ]);

          if (insertError) {
            console.error(insertError);
            swal("Ошибка", "Не удалось добавить участников игры", "error");
            return;
          }
          participantId++;
        }
      }

      if (editGame.winnerTeamId) {
        const { data: participants, error: participantsError } = await supabase
          .from("Participants_of_the_game")
          .select("team_id, athlet_id")
          .eq("game_id", editGame.id);

        if (participantsError) {
          console.error(participantsError);
          swal("Ошибка", "Не удалось получить участников игры", "error");
          return;
        }

        const { data: existingResults, error: resultsFetchError } =
          await supabase
            .from("Game_Results")
            .select("*")
            .eq("game_id", editGame.id);

        if (resultsFetchError) {
          console.error(resultsFetchError);
          swal("Ошибка", "Не удалось загрузить результаты игры", "error");
          return;
        }

        if (existingResults.length > 0) {
          for (const participant of participants) {
            const winner = participant.team_id == editGame.winnerTeamId;
            const { error: updateResultError } = await supabase
              .from("Game_Results")
              .update({
                winner: winner,
              })
              .eq("game_id", editGame.id)
              .eq("athlet_id", participant.athlet_id);

            if (updateResultError) {
              console.error(updateResultError);
              swal("Ошибка", "Не удалось обновить результаты игры", "error");
              return;
            }
          }
        } else {
          const { data: grid } = await supabase
            .from("Game_Results")
            .select("id")
            .order("id", { ascending: false })
            .limit(1);
          let gamerId = grid.length > 0 ? grid[0].id + 1 : 1;
          for (const participant of participants) {
            const winner = participant.team_id == editGame.winnerTeamId;

            const { error: insertResultError } = await supabase
              .from("Game_Results")
              .insert([
                {
                  id: gamerId,
                  game_id: editGame.id,
                  athlet_id: participant.athlet_id,
                  winner: winner,
                },
              ]);

            if (insertResultError) {
              console.error(insertResultError);
              swal("Ошибка", "Не удалось добавить результаты игры", "error");
              return;
            }
            gamerId++;
          }
        }
      }
      window.location.reload();
    } catch (error) {
      console.error(error);
      swal("Ошибка", "Не удалось сохранить изменения", "error");
    }
  };

  const handleRemoveAthlete = (teamIndex, athleteIndex) => {
    const updatedTeams = [...editGame.teams];
    updatedTeams[teamIndex].athletes.splice(athleteIndex, 1);
    setEditGame({ ...editGame, teams: updatedTeams });
  };

  const handleAddAthlete = (teamIndex) => {
    const updatedTeams = [...editGame.teams];
    updatedTeams[teamIndex].athletes.push("");
    setEditGame({ ...editGame, teams: updatedTeams });
  };

  const handleRemoveTeam = (teamIndex) => {
    const updatedTeams = [...editGame.teams];
    updatedTeams.splice(teamIndex, 1);
    setEditGame({ ...editGame, teams: updatedTeams });
  };

  const handleAddTeam = () => {
    const existingTeamIds = editGame.teams.map((team) => team.teamId);
    const newTeamId =
      existingTeamIds.length > 0 ? Math.max(...existingTeamIds) + 1 : 1;

    const updatedTeams = [
      ...editGame.teams,
      { teamId: newTeamId, athletes: [""] },
    ];

    setEditGame({ ...editGame, teams: updatedTeams });
  };

  const handleAddAthleteForm = (teamIndex) => {
    const updatedTeams = [...newGame.teams];
    updatedTeams[teamIndex].athletes.push({ id: "", name: "" });
    setNewGame({ ...newGame, teams: updatedTeams });
  };

  const handleRemoveAthleteForm = (teamIndex, athleteIndex) => {
    const updatedTeams = [...newGame.teams];
    updatedTeams[teamIndex].athletes.splice(athleteIndex, 1);
    setNewGame({ ...newGame, teams: updatedTeams });
  };

  const handleRemoveTeamForm = (teamIndex) => {
    const updatedTeams = [...newGame.teams];
    updatedTeams.splice(teamIndex, 1);
    setNewGame({ ...newGame, teams: updatedTeams });
  };

  const handleAddTeamForm = () => {
    setNewGame({
      ...newGame,
      teams: [
        ...newGame.teams,
        { teamId: newGame.teams.length + 1, athletes: [{ id: "", name: "" }] },
      ],
    });
  };

  const handleEditGame = (game) => {
    setEditGame(game);
    const teams = groupParticipantsByTeam(game.id);
    setEditGame({
      ...game,
      teams,
    });
  };

  const handleAthleteChange = (teamIndex, athleteIndex, athleteId) => {
    const updatedTeams = [...editGame.teams];
    if (teamIndex >= updatedTeams.length || teamIndex < 0) {
      console.error("Invalid team index");
      return;
    }

    const team = updatedTeams[teamIndex];
    if (
      !team.athletes ||
      athleteIndex >= team.athletes.length ||
      athleteIndex < 0
    ) {
      console.error("Invalid athlete index");
      return;
    }

    const selectedAthlete = athletes.find((athlete) => athlete.id == athleteId);
    if (!selectedAthlete) {
      console.error("Selected athlete not found");
      return;
    }

    team.athletes[athleteIndex] = {
      id: selectedAthlete.id,
      name: selectedAthlete.name,
    };

    // Обновить состояние
    updatedTeams[teamIndex] = team;
    setEditGame({ ...editGame, teams: updatedTeams });
  };

  const handleAthleteChangeForm = (teamIndex, athleteIndex, athleteId) => {
    const updatedTeams = [...newGame.teams];

    if (
      updatedTeams[teamIndex] &&
      updatedTeams[teamIndex].athletes[athleteIndex]
    ) {
      const selectedAthlete = athletes.find(
        (athlete) => athlete.id == athleteId
      );

      if (selectedAthlete) {
        updatedTeams[teamIndex].athletes[athleteIndex] = {
          id: selectedAthlete.id,
          name: selectedAthlete.name,
        };

        setNewGame({ ...newGame, teams: updatedTeams });
      } else {
        console.error("Selected athlete not found");
      }
    } else {
      console.error("Invalid team or athlete index");
    }
  };

  const handleSubmit = async () => {
    try {
      const currentYear = new Date().getFullYear();
      const gameDateYear = new Date(newGame.game_date).getFullYear();
      if (gameDateYear < currentYear || gameDateYear > currentYear + 20) {
        swal("Ошибка", `Дата должны быть между ${currentYear} и ${currentYear+20}`, "error");
        return;
      } 
      const did = disciplines.find(
        (disciplinn) =>
          disciplinn.name.toLowerCase() === disciplin.discipline.toLowerCase()
      );

      const allAthleteIds = [];
      for (const team of newGame.teams) {
        for (const athlete of team.athletes) {
          if (!athlete.id) {
            swal(
              "Ошибка",
              "Не удалось добавить игру. Все спортсмены должны быть выбраны.",
              "error"
            );
            return;
          }
          allAthleteIds.push(athlete.id);
        }
      }

      const duplicateAthletes = allAthleteIds.filter(
        (id, index) => allAthleteIds.indexOf(id) !== index
      );

      if (duplicateAthletes.length > 0) {
        swal("Ошибка", "Найдены дублирующиеся атлеты в командах.", "error");
        return;
      }

      const { data: dataid } = await supabase
        .from("Games")
        .select("id")
        .order("id", { ascending: false })
        .limit(1);

      const { data, error } = await supabase
        .from("Games")
        .insert([
          {
            id: dataid[0].id + 1,
            game_date: newGame.game_date,
            stadium_id: newGame.stadium_id,
            discipline_id: did.id,
          },
        ])
        .select();

      if (error) throw error;

      const newGameId = data[0].id;

      const { data: pid } = await supabase
        .from("Participants_of_the_game")
        .select("id")
        .order("id", { ascending: false })
        .limit(1);

      var x = pid[0].id + 1;

      for (const team of newGame.teams) {
        for (const athlete of team.athletes) {
          await supabase.from("Participants_of_the_game").insert([
            {
              id: x,
              game_id: newGameId,
              athlet_id: athlete.id,
              team_id: team.teamId,
            },
          ]);
          x = x + 1;
        }
      }

      window.location.reload();

      setNewGame({
        game_date: "",
        stadium_id: "",
        teams: [{ teamId: 1, athletes: [{ id: "", name: "" }] }],
      });
    } catch (error) {
      console.error(error);
      swal("Ошибка", "Не удалось добавить игру", "error");
    }
  };

  const handleDeleteGame = async (game) => {
    console.log(game);
    try {
      const {data: grData} = await supabase.from("Game_Results").delete().eq("game_id", game.id);
      const { data: pdata } = await supabase
        .from("Participants_of_the_game")
        .delete()
        .eq("game_id", game.id)
        .select();
      if (pdata) {
        const { data } = await supabase
          .from("Games")
          .delete()
          .eq("id", game.id)
          .select();
        if (data) window.location.reload();
        else swal("Ошибка", "Не удалось удалить игру", "error");
      } else {
        swal("Ошибка", "Не удалось удалить игру", "error");
      }
    } catch (error) {
      console.error(error);
      swal("Ошибка", "Не удалось удалить игру", "error");
    }
  };

  const handleCancelEdit = () => {
    setEdit(false);
    setEditGame(null);
  };

  const groupParticipantsByTeam = (gameId) => {
    const gameParticipants = participants.filter(
      (participant) => participant.game_id === gameId
    );
    const teams = [...new Set(gameParticipants.map((p) => p.team_id))];

    return teams.map((teamId) => ({
      teamId,
      athletes: gameParticipants
        .filter((p) => p.team_id === teamId)
        .map((p) => ({
          id: p.athlet.id,
          name: p.athlet.name,
        })),
    }));
  };

  const deleteResults = async (game) =>{
    const error = await supabase.from("Game_Results").delete().eq("game_id", game.id)
    if(error.status != 204) {
      console.log(error)
      swal("Ошибка", "Не удалось удалить результаты игры", "error");
      return;
    }
    else{
      window.location.reload();
    }
  };

  const setDefaultWinner = () => {
    if (!editGame || !editGame.id || !participants.length || !gameResults.length) return "";
  
    const gameParticipants = participants.filter(
      (participant) => participant.game_id === editGame.id
    );
  
    console.log(gameParticipants);
  
    const gameResultsForGame = gameResults.filter(
      (result) => result.game_id === editGame.id
    );
  
    console.log(gameResultsForGame);
  
    const winningAthletes = gameResultsForGame
      .filter((result) => result.winner)
      .map((result) => result.athlet_id);
  
    console.log(winningAthletes);

    const winningTeams = gameParticipants
      .filter((participant) => winningAthletes.includes(participant.athlet.id))
      .map((participant) => participant.team_id);
  
    return winningTeams.length > 0 ? [...new Set(winningTeams)][0] : "";
  };
  

  const filteredGames = games
    .filter((game) => {
      const discipline = disciplines.find(
        (disciplinn) =>
          disciplinn.id === game.discipline.id &&
          disciplinn.name ===
            disciplin.discipline.charAt(0).toUpperCase() +
              disciplin.discipline.slice(1)
      );
      return Boolean(discipline);
    })
    .filter((game) => game.game_date.includes(searchGame));
    

  return (
    <div className="App">
      <h1 className="discipline-title">
        {disciplin.discipline.charAt(0).toUpperCase() +
          disciplin.discipline.slice(1)}
      </h1>
      <input
        type="date"
        placeholder="Поиск по дате"
        className="search-input"
        onChange={(e) => setSearchGame(e.target.value)}
      />
      <div className="view">
        {loading ? (
          <h1>Загрузка...</h1>
        ) :  (
          <>
            {filteredGames.length > 0
              ? filteredGames.map((game) => (
                  <div key={game.id} className="game-card">
                    {edit && editGame && editGame.id === game.id ? (
                      <div className="edit-form">
                        <h2 className="edit-title">Редактирование</h2>
                        <p className="form-item">
                          <strong>Дата игры: </strong>
                          
                          <input
                            type="datetime-local"
                            className="game-input"
                            value={editGame.game_date || ""}
                            onChange={(e) =>
                              setEditGame({
                                ...editGame,
                                game_date: e.target.value,
                              })
                            }
                          />
                        </p>
                        <p className="form-item">
                          <strong>Стадион: </strong>
                          <select
                            value={editGame.stadium?.id || ""}
                            className="game-input"
                            onChange={(e) =>
                              setEditGame({
                                ...editGame,
                                stadium: { id: e.target.value },
                              })
                            }
                          >
                            <option value="">Выберите стадион</option>
                            {stadiums.map((stadium) => (
                              <option key={stadium.id} value={stadium.id}>
                                {stadium.name}
                              </option>
                            ))}
                          </select>
                        </p>
                        {editGame.teams.map((team, teamIndex) => (
                          <div key={teamIndex} className="team-form">
                            <p>
                              <strong>Команда {team.teamId}:</strong>
                            </p>
                            {team.athletes.map((athlete, athleteIndex) => (
                              <div
                                key={athleteIndex}
                                className="athlete-select"
                              >
                                <select
                                  value={athlete.id || ""}
                                  defaultValue={athlete.id}
                                  className="game-input"
                                  onChange={(e) =>
                                    handleAthleteChange(
                                      teamIndex,
                                      athleteIndex,
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Выберите спортсмена</option>
                                  {athletes.map((athlet) => (
                                    <option key={athlet.id} value={athlet.id}>
                                      {athlet.name}
                                    </option>
                                  ))}
                                </select>
                                <button
                                  onClick={() =>
                                    handleRemoveAthlete(teamIndex, athleteIndex)
                                  }
                                  className="remove-athlete-button"
                                >
                                  Удалить спортсмена
                                </button>
                              </div>
                            ))}
                            <button
                              onClick={() => handleAddAthlete(teamIndex)}
                              className="add-athlete-button"
                            >
                              + Спортсмен
                            </button>
                            <button
                              onClick={() => handleRemoveTeam(teamIndex)}
                              className="remove-team-button"
                            >
                              Удалить команду
                            </button>
                          </div>
                        ))}
                        <button
                          onClick={handleAddTeam}
                          className="add-team-button"
                        >
                          + Команда
                        </button>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyItems: "center",
                            alignItems: "center",
                          }}
                        >
                          <p>
                            <strong>Команда-победитель: </strong>
                            <select
                              className="game-input"
                              defaultValue={setDefaultWinner()}
                              onChange={(e) =>
                                setEditGame({
                                  ...editGame,
                                  winnerTeamId: e.target.value,
                                })
                              }
                            >
                              {" "}
                              <option value="">Выберите команду</option>
                              {editGame.teams.map((team) => (
                                <option key={team.teamId} value={team.teamId}>
                                  Команда {team.teamId}
                                </option>
                              ))}
                            </select>
                          </p>
                          <button
                            className="edit-game-button"
                            style={{ margin: "10px", height: "25px" }}
                            onClick={() => deleteResults(game)}
                          >
                            Удалить результаты
                          </button>
                        </div>

                        <div className="game-item-actions">
                          <button
                            className="edit-game-button"
                            onClick={handleSaveEdit}
                          >
                            Сохранить
                          </button>
                          <button
                            className="edit-game-button"
                            onClick={handleCancelEdit}
                          >
                            Отмена
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        <h2 className="game-title">Игра №{game.id}</h2>
                        <p className="game-info">
                          <strong>Дата игры:</strong>{" "}
                          {formatDate(game.game_date)}
                        </p>
                        <p className="game-info">
                          <strong>Стадион:</strong> {game.stadium.name}
                        </p>
                        {groupParticipantsByTeam(game.id).map((team) => (
                          <div key={team.teamId} className="team-info">
                            <strong>Команда {team.teamId}:</strong>
                            <ul style={{ listStyle: "none" }}>
                              {team.athletes.map((athlete, index) => (
                                <li key={index}>{athlete.name}</li>
                              ))}
                            </ul>
                          </div>
                        ))}

                        {gameResults.some(
                          (result) => result.game_id === game.id
                        ) && (
                          <div className="game-result">
                            <h2 className="discipline-title">
                              Результат игры:
                            </h2>
                            {Array.from(
                              new Set(
                                gameResults
                                  .filter(
                                    (result) =>
                                      result.game_id === game.id &&
                                      result.winner
                                  )
                                  .map((winnerResult) => {
                                    const team = groupParticipantsByTeam(
                                      game.id
                                    ).find((t) =>
                                      t.athletes.some(
                                        (a) => a.id === winnerResult.athlet_id
                                      )
                                    );
                                    return (
                                      team?.teamId || "Неизвестной команды"
                                    );
                                  })
                              )
                            ).map((teamId, index) => (
                              <p key={index}>
                                <strong>Победа команды: </strong>№ {teamId}
                              </p>
                            ))}
                          </div>
                        )}

                        {isLogged && (
                          <div className="card_button">
                            <button
                              className="supp_button"
                              onClick={() => {
                                handleEditGame(game);
                                setEdit(true);
                              }}
                            >
                              Изменить
                            </button>
                            <button
                              className="supp_button"
                              onClick={() => handleDeleteGame(game)}
                            >
                              Удалить
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ))
 : !isLogged && <h1>Нет игр в данной дисциплине</h1>}

            {isLogged && (
              <div className="game-card">
                <h2 className="game-title">Добавить новую игру</h2>
                <p className="form-item">
                  <strong>Дата игры: </strong>
                  <input
                    type="datetime-local"
                    className="game-input"
                    value={newGame.game_date}
                    onChange={(e) =>
                      setNewGame({ ...newGame, game_date: e.target.value })
                    }
                  />
                </p>
                <p className="form-item">
                  <strong>Стадион: </strong>
                  <select
                    value={newGame.stadium_id}
                    className="game-input"
                    onChange={(e) =>
                      setNewGame({ ...newGame, stadium_id: e.target.value })
                    }
                  >
                    <option value="">Выберите стадион</option>
                    {stadiums.map((stadium) => (
                      <option key={stadium.id} value={stadium.id}>
                        {stadium.name}
                      </option>
                    ))}
                  </select>
                </p>
                {newGame.teams.map((team, teamIndex) => (
                  <div key={teamIndex} className="team-form">
                    <p>
                      <strong>Команда {team.teamId}:</strong>
                    </p>
                    {team.athletes.map((athlete, athleteIndex) => (
                      <div key={athleteIndex} className="athlete-select">
                        <select
                          value={athlete.id}
                          className="game-input"
                          onChange={(e) =>
                            handleAthleteChangeForm(
                              teamIndex,
                              athleteIndex,
                              e.target.value
                            )
                          }
                        >
                          <option value="">Выберите спортсмена</option>
                          {athletes.map((athlet) => (
                            <option key={athlet.id} value={athlet.id}>
                              {athlet.name}
                            </option>
                          ))}
                        </select>
                        <button
                          onClick={() =>
                            handleRemoveAthleteForm(teamIndex, athleteIndex)
                          }
                          className="remove-athlete-button"
                        >
                          Удалить спортсмена
                        </button>
                      </div>
                    ))}
                    <button
                      onClick={() => handleAddAthleteForm(teamIndex)}
                      className="add-athlete-button"
                    >
                      + Спортсмен
                    </button>
                    <button
                      onClick={() => handleRemoveTeamForm(teamIndex)}
                      className="remove-team-button"
                    >
                      Удалить команду
                    </button>
                  </div>
                ))}
                <button onClick={handleAddTeamForm} className="add-team-button">
                  + Команда
                </button>
                <button className="action-button" onClick={handleSubmit}>
                  Добавить игру
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Disciplines;