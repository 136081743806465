import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "./Red";
import { useState } from "react";
import "./App.css";
import swal from "sweetalert";
import Cookies from "universal-cookie";

const cookies = new Cookies();

function Sports() {
  const [disciplines, setDisciplines] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editDiscipline, setEditDiscipline] = useState(null);
  const [searchDiscipline, setSearchDiscipline] = useState("");
  const [loading, setLoading] = useState(true);
  const [isLogged, setIsLogged] = useState(true);
  const [sports, setSports] = useState(null);
  const { sport } = useParams();
  const navigate = useNavigate();

  const searchDisciplines = disciplines.filter((discipline) =>
    discipline.name.toLowerCase().includes(searchDiscipline.toLowerCase())
  );

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      const { data: sportData } = await supabase.from("Sports").select();
      const { data, error } = await supabase.from("Disciplines").select(`
            id,
            name,
            Sports!Disciplines_sport_id_fkey(id, name)
          `);

      if (error) {
        console.error(error);
        setLoading(false);
        return;
      }
      setSports(sportData);
      const sportExists = sportData.some(
        (item) => item.name.toLowerCase() === sport.toLowerCase()
      );
      const filteredDisciplines = data.filter(
        (game) => game.Sports.name.toLowerCase() === sport.toLowerCase()
      );
      if (filteredDisciplines.length === 0 && !sportExists) {
        setLoading(false);
        navigate("/");
      }
      setDisciplines(filteredDisciplines);
      setLoading(false);
    }

    if (cookies.get("logged") == true) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
    fetch();
  }, []);

  const addDiscipline = async () => {
    const name = document.getElementById("disciplineName");
    if (!name) return;
    const { data: dataid, error: errorid } = await supabase
      .from("Disciplines")
      .select("id")
      .order("id", { ascending: false })
      .limit(1);
    if (errorid) console.log(errorid);

    const sportid = sports.find(
      (game) => game.name.toLowerCase() === sport.toLowerCase()
    );

    const { data, error } = await supabase
      .from("Disciplines")
      .insert([
        {
          id: dataid[0].id + 1,
          name: name.value,
          sport_id: sportid.id,
        },
      ])
      .select();

    if (error) {
      console.error(error);
      return;
    }

    window.location.reload();
    document.getElementById("disciplineName").value = "";
  };

  async function editDisciplineDetails() {
    const { data, error } = await supabase
      .from("Disciplines")
      .update({ name: editDiscipline.name })
      .eq("id", editDiscipline.id);

    if (error) {
      console.error(error);
      return;
    }

    setDisciplines((prevState) =>
      prevState.map((item) =>
        item.id === editDiscipline.id
          ? { ...item, name: editDiscipline.name }
          : item
      )
    );
    setEdit(false);
    setEditDiscipline(null);
  }

  async function deleteDiscipline(id) {
    const { error } = await supabase.from("Disciplines").delete().eq("id", id);

    if (error) {
      if (error.code == 23503) {
        swal("Ошибка!", "Удалите матчи с этой дисциплиной", "error");
      } else {
        console.error(error);
        swal("Ошибка!", "Не удалось удалить дисциплину", "error");
      }
    } else {
      window.location.reload();
    }
  }

  return (
    <div className="App">
      <h1 id="games" className="games-header">
        {sport.charAt(0).toUpperCase() +
          sport.slice(1)}
      </h1>
      <input
        type="search"
        placeholder="Поиск"
        className="search-input"
        onChange={(e) => setSearchDiscipline(e.target.value)}
      />

      <div className="view">
        {loading ? (
          <h1>Загрузка...</h1>
        ) : disciplines.length === 0 ? (
          isLogged ? (
            <div className="game-item game-form add-game-form">
              <h2 className="form-title">Добавить дисциплину</h2>
              <input
                id="disciplineName"
                className="game-input"
                placeholder="Название дисциплины"
              />
              <button className="add-game-button" onClick={addDiscipline}>
                Добавить
              </button>
            </div>
          ) : (
            <h2>Информация о дисциплинах для этого вида спорта отсутствует.</h2>
          )
        ) : searchDisciplines.length > 0 ? (
          <div className="game-buttons">
            {searchDisciplines.map((discipline) => (
              <div
                className="game-item"
                onClick={(e) => {
                  if (
                    !e.target.closest(".edit-game-button") &&
                    !e.target.closest(".delete-game-button") &&
                    !edit
                  ) {
                    navigate(
                      `/${sport.toLowerCase()}/${discipline.name.toLowerCase()}`
                    );
                  }
                }}
              >
                {edit && editDiscipline?.id === discipline.id ? (
                  isLogged && (
                    <>
                      <h2 className="form-title">
                        <strong>Название дисциплины:</strong>
                        <input
                          value={editDiscipline.name}
                          className="game-input"
                          onChange={(e) =>
                            setEditDiscipline({
                              ...editDiscipline,
                              name: e.target.value,
                            })
                          }
                        />
                      </h2>
                      <div className="game-item-actions">
                        <button
                          className="edit-game-button"
                          onClick={editDisciplineDetails}
                        >
                          Сохранить
                        </button>
                        <button
                          className="edit-game-button"
                          onClick={() => {
                            setEdit(false);
                            setEditDiscipline(null);
                          }}
                        >
                          Отменить
                        </button>
                      </div>
                    </>
                  )
                ) : (
                  <>
                    <h2 className="game-title">{discipline.name}</h2>
                    {isLogged && (
                      <div className="game-item-actions">
                        <button
                          className="edit-game-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            setEdit(true);
                            setEditDiscipline(discipline);
                          }}
                        >
                          Изменить
                        </button>
                        <button
                          className="delete-game-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteDiscipline(discipline.id);
                          }}
                        >
                          Удалить
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
            {isLogged && (
              <div className="game-item game-form add-game-form">
                <h2 className="form-title">Добавить дисциплину</h2>
                <input
                  id="disciplineName"
                  className="game-input"
                  placeholder="Название дисциплины"
                />
                <button className="add-game-button" onClick={addDiscipline}>
                  Добавить
                </button>
              </div>
            )}
          </div>
        ) : (
          <h2>Результаты не найдены</h2>
        )}
      </div>
    </div>
  );
}

export default Sports;
