import React, { useState, useEffect } from "react";
import { supabase } from "./Red";

const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return new Date(dateString).toLocaleDateString("ru-RU", options);
};

const Report = () => {
  const [games, setGames] = useState([]);
  const [games_res, setGames_res] = useState([]);
  const [athletes, setAthletes] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [searchGame, setSearchGame] = useState("");
  const [loading, setLoading] = useState(true);

  const searchGames = games.filter((game) => {
    const discipline = disciplines.find((d) => d.id === game.discipline_id);
    return discipline && discipline.name.toLowerCase().includes(searchGame.toLowerCase());
  });  

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      const [game, game_res, athlet, participiant, discipline] =
        await Promise.all([
          supabase.from("Games").select(
            `
            id,
            game_date, 
            discipline_id,
            stadium:Stadiums(id, name)
        `
          ),
          supabase.from("Game_Results").select(),
          supabase.from("Athletes").select(),
          supabase.from("Participants_of_the_game").select(),
          supabase.from("Disciplines").select(`
            id,
            name,
            sport:Sports(id,name)
            `),
        ]);

      setGames(game.data);
      setGames_res(game_res.data);
      setAthletes(athlet.data);
      setParticipants(participiant.data);
      setDisciplines(discipline.data);
      setLoading(false);
    }

    fetch();
  }, []);

  const getParticipantsByGame = (gameId) => {
    const teams = participants
      .filter((p) => p.game_id === gameId)
      .reduce((acc, curr) => {
        const teamId = curr.team_id;
        const athlete = athletes.find((a) => a.id === curr.athlet_id);
        if (!acc[teamId]) acc[teamId] = [];
        if (athlete) acc[teamId].push(athlete.name);
        return acc;
      }, {});

    return Object.entries(teams).map(([teamId, members]) => ({
      teamId,
      members,
    }));
  };

  const getGameDiscipline = (disciplineId) => {
    const discipline = disciplines.find((d) => d.id === disciplineId);
    return discipline ? discipline.name : "Неизвестная дисциплина";
  };

  const getGameResult = (gameId) => {
    const winners = games_res.filter((r) => r.game_id === gameId && r.winner);
    if (winners.length > 0) {
      const teamIds = winners.map(
        (r) =>
          participants.find(
            (p) => p.athlet_id === r.athlet_id && p.game_id === gameId
          )?.team_id
      );
      const uniqueTeamIds = [...new Set(teamIds)];
      return uniqueTeamIds
        .map((teamId) => {
          const teamMembers = participants
            .filter((p) => p.team_id === teamId && p.game_id === gameId)
            .map((p) => {
              const athlete = athletes.find((a) => a.id === p.athlet_id);
              return athlete ? athlete.name : "Неизвестный атлет";
            });

          return teamId;
        })
        .join("; ");
    } else {
      return null;
    }
  };

  return (
    <div className="App">
      <h1>Отчет по играм</h1>
      <input
        placeholder="Поиск по дисциплине"
        className="search-input"
        onChange={(e) => setSearchGame(e.target.value)}
      />
      <div className="view">
        {loading ? (
          <h1>Загрузка...</h1>
        ) : searchGames.length > 0 ? (
          <>
            {searchGames.map((game) => (
              <div key={game.id} className="game-card">
                <h2 className="game-title">
                  {getGameDiscipline(game.discipline_id)}
                </h2>
                <p className="game-info">
                  <strong>Дата:</strong> {formatDate(game.game_date)}
                </p>
                <p className="game-info">
                  <strong>Стадион:</strong>{" "}
                  {game.stadium?.name || "Неизвестный"}
                </p>
                <div className="team-info">
                  <strong>Участники:</strong>
                  {getParticipantsByGame(game.id).map((team) => (
                    <div key={team.teamId}>
                      <p>
                        <strong>Команда {team.teamId}:</strong>
                      </p>
                      <ul style={{ listStyle: "none" }}>
                        {team.members.map((member, index) => (
                          <li key={index}>{member}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
                <div className="game-result">
                  <h2 className="discipline-title">Результат игры: </h2>
                  {getGameResult(game.id) ? (
                    <p>
                      <strong>Победа команды:</strong> №{getGameResult(game.id)}
                    </p>
                  ) : (
                    <p style={{textAlign:"center"}}><strong>Результатов ещё нет</strong></p>
                  )}
                </div>
              </div>
            ))}
          </>
        ) : (
          <h2>Результаты не найдены</h2>
        )}
      </div>
    </div>
  );
};

export default Report;
