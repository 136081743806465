import React, { useEffect } from "react";
import { useState } from "react";
import { supabase } from "./Red";
import swal from "sweetalert";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

const cookies = new Cookies();

export default function Header() {
  const navigate = useNavigate();
  const [overlayType, setOverlayType] = useState(null);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [coaches, SetCoaches] = useState(null);
  const [applications, setApplications] = useState([]);
  const [isLogged, setIsLogged] = useState(false);

  async function addToAthletes(id) {
    try {
      const { data, error: errorid } = await supabase
        .from("Applications_of_athletes")
        .select(
          `
              id,
              name,
              age,
              about,
              Coaches (id, name),
              phone
            `
        )
        .eq("id", id);

      if (errorid) {
        console.log(errorid);
        return;
      }

      deleteApplication(id);

      const { data: dataid } = await supabase
        .from("Athletes")
        .select("id")
        .order("id", { ascending: false })
        .limit(1);

      const athlet = data[0];
      const { error } = await supabase
        .from("Athletes")
        .insert([
          {
            id: dataid[0].id + 1,
            name: athlet.name,
            age: athlet.age,
            coach_id: athlet.Coaches.id,
          },
        ])
        .select();

      if (error) {
        console.log(error);
        return;
      }
      window.location.reload();
    } catch (e) {
      console.log(e);
      swal("Ошибка!", "Не удалось добавить спортсмена", "error");
    }
  }

  async function deleteApplication(id) {
    try {
      const { error } = await supabase
        .from("Applications_of_athletes")
        .delete()
        .eq("id", id);

      if (error) {
        console.log(error);
      }

      const { data, error: fetchError } = await supabase.from(
        "Applications_of_athletes"
      ).select(`
          id,
          name,
          age,
          about,
          Coaches (id, name),
          phone
        `);

      if (fetchError) {
        console.log(fetchError);
      }
      swal("Успешно", "Завяка удалена", "success");
      setApplications(data);
    } catch (e) {
      console.log(e);
    }
  }

  const loginUser = async () => {
    const login = document.getElementById("login");
    const password = document.getElementById("password");

    if (login.value !== "" && password.value !== "") {
      try {
        const { data, error } = await supabase.auth.signInWithPassword({
          email: login.value,
          password: password.value,
        });
        if (error) {
          console.log(error);
          swal("Ошибка!", "Неверный логин или пароль", "error");
        }
        if (data.session?.access_token) {
          cookies.set("logged", true);
          setOverlayType(null);
          window.location.reload();
        }
      } catch (e) {
        console.log(e);
        swal("Ошибка!", "Неверный логин или пароль", "error");
      }
    } else {
      swal("Ошибка!", "Заполните все поля", "error");
    }
  };

  const addApplication = async () => {
    const Name = document.getElementById("FIO");
    const Age = document.getElementById("Age");
    const About = document.getElementById("About");
    const number = document.getElementById("NumberPhone");

    if (Name.value && Age.value > 17 && About.value && number.value) {
      try {
        const { error } = await supabase
          .from("Applications_of_athletes")
          .insert([
            {
              name: Name.value,
              age: Age.value,
              coach_id: selectedCoach,
              about: About.value,
              phone: number.value
            },
          ]);
        if (error) throw error;
        else swal("Успешно", "Завяка была отправлена", "success");

        const { data, error: fetchError } = await supabase.from(
          "Applications_of_athletes"
        ).select(`
            id,
            name,
            age,
            about,
            Coaches (id, name),
            phone
          `);
        if (fetchError) throw fetchError;

        setApplications(data);
        setOverlayType(null);
      } catch (e) {
        console.log(e);
        swal("Ошибка!", "Не удалось отправить заявку", "error");
      }
    } else {
      swal("Ошибка!", "Заполните все поля корректно", "error");
    }
  };

  const signOut = () => {
    cookies.remove("logged");
    window.location.reload();
  };

  useEffect(() => {
    async function fetch() {
      const { data: coachesData, error: coachesError } = await supabase.from(
        "Coaches"
      ).select(`
        id,
        age,
        name,
        experience
        `);

      const { data: applicationsData, error: applicationsError } =
        await supabase.from("Applications_of_athletes").select(`
                id,
                name,
                age,
                about,
                Coaches (id, name),
                phone
              `);
      if (coachesError)
        console.error("Ошибка загрузки тренеров:", coachesError);
      if (applicationsError)
        console.error("Ошибка загрузки тренеров:", applicationsError);
      SetCoaches(coachesData || []);
      setApplications(applicationsData || []);
    }
    if (cookies.get("logged")) {
      setIsLogged(true);
    }
    fetch();
  }, []);

  return (
    <header className="App-header">
      <nav className="navbar">
        <ul className="nav_redirection">
          <li>
            <a onClick={() => navigate("/")}>Виды спорта</a>
          </li>
          <li>
            <a onClick={() => navigate("/disciplines")}>Дисциплины</a>
          </li>
          <li>
            <a onClick={() => navigate("/athletes")}>Спортсмены</a>
          </li>
          <li>
            <a onClick={() => navigate("/coaches")}>Тренеры</a>
          </li>
          <li>
            <a onClick={() => navigate("/sport_stadiums")}>Спортивные комплексы</a>
          </li>
          <li>
            <a onClick={() => navigate("/leaderboard")}>Таблица лидеров</a>
          </li>
          <li>
            <a onClick={() => setOverlayType("blank")}>Хотите поучаствовать?</a>
          </li>
          <li>
            <a onClick={() => navigate("/report")}>Посмотреть отчёт</a>
          </li>
        </ul>
        {isLogged ? (
          <div className="log_button">
            <button onClick={() => setOverlayType("applications")}>
              Полученные заявки
            </button>
            <button onClick={signOut}>Выйти из аккаунта</button>
          </div>
        ) : (
          <div className="log_button">
            <button onClick={() => setOverlayType("signIn")}>Войти</button>
          </div>
        )}
      </nav>

      {overlayType === "signIn" && (
        <Overlay type="signIn" onClose={() => setOverlayType(null)}>
          <p className="Field_login">
            Логин: <input id="login" className="login" />
          </p>
          <p className="Field_login">
            Пароль: <input id="password" type="password" className="password" />
          </p>
          <div className="overlay_buttons">
            <button onClick={loginUser}>Войти</button>
          </div>
        </Overlay>
      )}

      {overlayType === "blank" && (
        <Overlay type="blank" onClose={() => setOverlayType(null)}>
          <p className="Field_blank">
            Введите ФИО: <input id="FIO" className="FIO" />
          </p>
          <p className="Field_blank">
            Введите возраст:{" "}
            <input id="Age" className="Age" min="0" type="number" />
          </p>
          <p className="Field_blank">
            Выберите желаемого тренера:
            <select
              id="Coach_desired"
              className="Coach_desired"
              onChange={(e) => setSelectedCoach(e.target.value)}
            >
              <option value="">Выберите тренера</option>
              {coaches.map((coach) => (
                <option key={coach.id} value={coach.id}>
                  {coach.name}
                </option>
              ))}
            </select>
          </p>
          <p className="Field_blank">
            Расскажите о себе:{" "}
            <textarea id="About" className="About" rows="3" />
          </p>
          <p className="Field_blank">
            Введите номер телефона для связи:{" "}
            <input id="NumberPhone" className="Age"/>
          </p>
          <div className="overlay_buttons">
            <button onClick={addApplication}>Отправить заявку</button>
          </div>
        </Overlay>
      )}

      {overlayType === "applications" && (
        <Overlay type="applications" onClose={() => setOverlayType(null)}>
          <ul className="overlay_ul">
            {applications.map((app) => (
              <li key={app.id}>
                <div className="overlay_strong">
                  <strong>Фио:</strong> {app.name}
                </div>
                <div className="overlay_strong">
                  <strong>Возраст:</strong> {app.age}
                </div>
                <div className="overlay_strong">
                  <strong>О кандидате:</strong> {app.about}
                </div>
                <div className="overlay_strong">
                  <strong>Желаемый тренер:</strong> {app.Coaches.name}
                </div>
                <div className="overlay_strong">
                  <strong>Номер телефона:</strong> {app.phone}
                </div>
                <div className="overlay_buttons">
                  <button
                    className="overlay_button"
                    onClick={() => addToAthletes(app.id)}
                  >
                    Добавить к спортсменам
                  </button>
                  <button
                    className="overlay_button"
                    onClick={() => deleteApplication(app.id)}
                  >
                    Удалить
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </Overlay>
      )}
    </header>
  );
}
  
  function Overlay({ type, onClose, children }) {
    let title;
    switch (type) {
      case "signIn":
        title = "Вход в аккаунт";
        break;
      case "blank":
        title = "Бланк регистрации спортсмена";
        break;
      case "applications":
        title = "Полученные заявки";
        break;
      default:
        return null;
    }
  
    return (
      <div className="overlay">
        <div className={`overlay-content_${type}`}>
          <h2 className="overlay_h">{title}</h2>
          {children}
          <button onClick={onClose}>Закрыть</button>
        </div>
      </div>
    );
  } 