import React, { useEffect } from "react";
import { supabase } from "./Red";
import { useState } from "react";
import "./App.css";

function LeaderBoard() {
  const [gameResults, setGameResults] = useState([]);
  const [athletes, setAthletes] = useState([]);
  const [loading, setLoading] = useState([]);
  const [searchAthlete, setSearchAthlete] = useState("");

  const filteredAthletes = athletes.filter((athlete) =>
    athlete.name.toLowerCase().includes(searchAthlete.toLowerCase())
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const { data: athleteData, error: athleteError } = await supabase
          .from("Athletes")
          .select("id, name");

        if (athleteError) throw athleteError;
        setAthletes(athleteData);


        const { data: gameResultData, error: gameResultError } = await supabase
          .from("Game_Results")
          .select("id, athlet_id, winner");

        if (gameResultError) throw gameResultError;
        setGameResults(gameResultData);

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const calculateLeaderBoard = () => {
    const winsByAthlete = {};

    gameResults.forEach((result) => {
      if (result.winner) {
        winsByAthlete[result.athlet_id] =
          (winsByAthlete[result.athlet_id] || 0) + 1;
      }
    });

    return filteredAthletes
      .map((athlete) => ({
        id: athlete.id,
        name: athlete.name,
        wins: winsByAthlete[athlete.id] || 0,
      }))
      .filter((athlete) => athlete.wins > 0);
  };

  const leaderBoard = calculateLeaderBoard();

  return (
    <div className="App" style={{textAlign:"center"}}>
      <h1>Таблица лидеров</h1>
      <input
        type="text"
        placeholder="Поиск атлета"
        onChange={(e) => setSearchAthlete(e.target.value)}
        className="search-input"
      />
      {loading ? (
        <h1>Загрузка...</h1>
      ) : filteredAthletes.length > 0 ? (
        <table className="leaderboard-table">
          <thead>
            <tr>
              <th>№</th>
              <th>Имя атлета</th>
              <th>Победы</th>
            </tr>
          </thead>
          <tbody>
            {leaderBoard
              .sort((a, b) => b.wins - a.wins)
              .map((athlete, index) => (
                <tr key={athlete.id}>
                  <td>{index + 1}</td>
                  <td>{athlete.name}</td>
                  <td>{athlete.wins}</td>
                </tr>
              ))}
          </tbody>
        </table>
        ) : (
            <h2>Результаты не найдены</h2>
          )}
      
    </div>
  );
}

export default LeaderBoard;