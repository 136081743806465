import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "./Red";
import { useState } from "react";
import "./App.css";
import swal from "sweetalert";
import Cookies from "universal-cookie";

const cookies = new Cookies();

function AllDisc() {
  const [disciplines, setDisciplines] = useState([]);
  const [sports, setSports] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editDiscipline, setEditDiscipline] = useState(null);
  const [searchDiscipline, setSearchDiscipline] = useState("");
  const [selectedDisc, setSelectedDisc] = useState(null)
  const [loading, setLoading] = useState(true);
  const [isLogged, setIsLogged] = useState(true);
  const navigate = useNavigate();

  const searchDisciplines = disciplines.filter((discipline) =>
    discipline.name.toLowerCase().includes(searchDiscipline.toLowerCase())
  );

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      const { data, error } = await supabase.from("Disciplines").select(`
                id,
                name,
                Sports!Disciplines_sport_id_fkey(id, name)
              `);

      if (error) {
        console.error(error);
        setLoading(false);
        return;
      }
      setDisciplines(data);
      const { data: sportData, error: sportError } = await supabase.from(
        "Sports"
      ).select(`
        id,
        name
    `);
      if (sportError) {
        console.error(sportError);
        setLoading(false);
        return;
      }
      setSports(sportData);
      setLoading(false);
    }

    if (cookies.get("logged") == true) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
    fetch();
  }, []);

  const addDiscipline = async () => {
    const name = document.getElementById("disciplineName");
    if (!name || selectedDisc) {
      swal("Ошибка!", "Введите данные", "error");
      return;
    }

    const { data: dataid, error: errorid } = await supabase
      .from("Disciplines")
      .select("id")
      .order("id", { ascending: false })
      .limit(1);
    if (errorid) {
      console.log(errorid);
      swal("Ошибка!", "Не удалось добавить дисципину", "error");
    }

    const { error } = await supabase
      .from("Disciplines")
      .insert([
        {
          id: dataid[0].id + 1,
          name: name.value,
          sport_id: selectedDisc,
        },
      ])
      .select();

    if (error) {
      console.error(error);
      swal("Ошибка!", "Не удалось добавить дисципину", "error");
      return;
    }

    window.location.reload();
  };

  async function redirect(discipline){
    console.log(discipline)
    navigate(`/${discipline.Sports.name.toLowerCase()}/${discipline.name.toLowerCase()}`);
  }

  async function editDisciplineDetails() {
    const { error } = await supabase
      .from("Disciplines")
      .update({ name: editDiscipline.name, sport_id: selectedDisc })
      .eq("id", editDiscipline.id);

    if (error) {
      console.error(error);
      swal("Ошибка!", "Не удалось изменить дисципину", "error");
      return;
    }

    window.location.reload();
  }

  async function deleteDiscipline(id) {
    const { error } = await supabase.from("Disciplines").delete().eq("id", id);

    if (error) {
      if (error.code == 23503) {
          swal("Ошибка!", "Удалите матчи с этой дисциплиной", "error");
        } else {
          console.error(error);
          swal("Ошибка!", "Не удалось удалить дисциплину", "error");
        }
    } else {
      window.location.reload();
    }
  }

  return (
    <div className="App">
      <h1 id="games" className="games-header">
        Дисциплины
      </h1>
      <input
        type="search"
        placeholder="Поиск"
        className="search-input"
        onChange={(e) => setSearchDiscipline(e.target.value)}
      />

      <div className="view">
        {loading ? (
          <h1>Загрузка...</h1>
        ) : searchDisciplines.length > 0 ? (
          <div className="game-buttons">
            {searchDisciplines.map((discipline) => (
              <div
                className="disc-item game-form"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  if (
                    !e.target.closest(".edit-game-button") &&
                    !e.target.closest(".delete-game-button") &&
                    !edit
                  ) {
                    redirect(discipline);
                  }
                }}
              >
                {edit && editDiscipline?.id === discipline.id ? (
                  isLogged && (
                    <>
                    <div style={{gap:"0px", display:"flex",flexDirection:"column",alignItems:"center"}}>
                        <h2 className="form-title">
                          <strong>Название дисциплины:</strong>
                          <input
                            value={editDiscipline.name}
                            className="game-input"
                            style={{height:"20px"}}
                            onChange={(e) =>
                              setEditDiscipline({
                                ...editDiscipline,
                                name: e.target.value,
                              })
                            }
                          />
                        </h2>
                        <p style={{margin: "0px", padding:"0px"}}><strong>Выберите вид спорта</strong></p>
                        <select
                          defaultValue={discipline.Sports.id}
                          className="game-input"
                          style={{height:"20px"}}
                          onChange={(e) =>
                            setSelectedDisc(e.target.value || null)
                          }
                        >
                          <option value="">Выберите вид спорта</option>
                          {sports.map((sport) => (
                            <option key={sport.id} value={sport.id}>
                              {sport.name}
                            </option>
                          ))}
                        </select>
                      
                      <div className="game-item-actions">
                        <button
                          className="edit-game-button"
                          onClick={editDisciplineDetails}
                        >
                          Сохранить
                        </button>
                        <button
                          className="edit-game-button"
                          onClick={() => {
                            setEdit(false);
                            setEditDiscipline(null);
                          }}
                        >
                          Отменить
                        </button>
                      </div>
                      </div>
                    </>
                  )
                ) : (
                  <>
                  <div style={{textAlign: "center", marginTop:"-10px"}}>
                  <h2 className="game-title">{discipline.name}</h2>
                  <p><strong>Вид спорта: </strong>{discipline.Sports.name}</p>
                  </div>
                   
                    {isLogged && (
                      <div className="game-item-actions">
                        <button
                          className="edit-game-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            setEdit(true);
                            setEditDiscipline(discipline);
                          }}
                        >
                          Изменить
                        </button>
                        <button
                          className="delete-game-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteDiscipline(discipline.id);
                          }}
                        >
                          Удалить
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
            {isLogged && (
              <div className="disc-item game-form">
                <h2 className="form-title">Добавить дисциплину</h2>
                <input
                  id="disciplineName"
                  className="game-input"
                  placeholder="Название дисциплины"
                />
                <select className="game-input"
                  onChange={(e) => setSelectedDisc(e.target.value || null)}
                >
                  <option value="">Выберите вид спорта</option>
                  {sports.map((sport) => (
                    <option key={sport.id} value={sport.id}>
                      {sport.name}
                    </option>
                  ))}
                </select>

                <button className="add-game-button" onClick={addDiscipline}>
                  Добавить
                </button>
              </div>
            )}
          </div>
        ) : (
          <h2>Результаты не найдены</h2>
        )}
      </div>
    </div>
  );
}

export default AllDisc;