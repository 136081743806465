import "./App.css";
import { createClient } from "@supabase/supabase-js";
import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

export var isLogged = false;
export var supabase = null;

const cookies = new Cookies();
supabase = createClient(
  "https://rwfwafwtgealqsznzheg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJ3ZndhZnd0Z2VhbHFzem56aGVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzA5NzMwODksImV4cCI6MjA0NjU0OTA4OX0.W8YfkHIAXmK76u54BdkOB_Sgme-YCVWNAFCn4DmfDyQ"
);

const Red = () => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchGame, setSearchGame] = useState("");
  const [edit, setEdit] = useState(false);
  const [editGame, setEditGame] = useState(null);
  const navigate = useNavigate();
  
  const searchGames = games.filter(
    (game) => game.name.toLowerCase().includes(searchGame.toLowerCase()),
  );

  async function addGame() {
    const name = document.getElementById("gameName");
    if (name.value !== "") {
      const { data: dataid, error: errorid } = await supabase
        .from("Sports")
        .select("id")
        .order("id", { ascending: false })
        .limit(1);

      if (errorid) console.log(errorid);
      try {
        const { error } = await supabase
          .from("Sports")
          .insert([
            {
              id: dataid[0].id + 1,
              name: name.value,
            },
          ])
          .select();
        if (error) console.log(error);
        else {
          window.location.reload();
        }
      } catch (e) {
        console.log(e);
        swal("Ошибка!", "Не удалось добавить вид спорта", "error");
      }
    } else {
      swal("Ошибка!", "Не удалось добавить вид спорта", "error");
    }
  }

  async function editGameDetails(gameId, gameData) {
    try {
      const error = await supabase
        .from("Sports")
        .update([
          {
            name: gameData.name,
          },
        ])
        .eq("id", gameId);
      if (error.status != 204) {
        console.log(error);
        swal("Ошибка!", "Не удалось измениь вид спорта", "error");
      } else {
        window.location.reload();
      }
    } catch (error) {
      swal("Ошибка!", "Не удалось изменить вид спорта", "error");
      console.log(error);
    }
  }

  async function deleteGame(gameId) {
    try {
      console.log(gameId);
      const {error} = await supabase.from("Disciplines").delete().eq("sport_id", gameId);
      if (error) {
        if (error.code == "23503") {
          console.log("2");
          swal("Ошибка!", "Удалите матчи с дисциплиной по этому виду спорта", "error");
        } else {
          console.error(error);
          swal("Ошибка!", "Не удалось удалить вид спорта", "error");
        }
      } else {
        const {error} = await supabase.from("Sports").delete().eq("id", gameId);
        window.location.reload();
      }
    } catch (error) {
      swal("Ошибка!", "Не удалось удалить вид спорта", "error");
      console.log(error);
    }
  }

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      const { data: athletesData, error: athletesError } = await supabase.from(
        "Sports"
      ).select(`
        id,
        name
      `);
      if (athletesError) console.log(athletesError);
      setGames(athletesData);
      setLoading(false);
    }

    function LoadCookies() {
      if (cookies.get("logged")) {
        isLogged = true;
      }
    }
    LoadCookies();
    fetch();
  }, []);

  return (
    <div className="App">
      
        <h1 id="games" className="games-header">
          Виды спорта
        </h1>
        <input
          type="search"
          placeholder="Поиск"
          className="search-input"
          onChange={(e) => setSearchGame(e.target.value)}
        />
        <div className="view">
          {loading ? (
            <h1>Загрузка...</h1>
          ) : searchGames.length > 0 ? (
            <>
              <div className="game-buttons">
                {searchGames.map((game) => (
                  <div
                    key={game.id}
                    className="game-item"
                    onClick={(e) => {
                      if (
                        !e.target.closest(".edit-game-button") &&
                        !e.target.closest(".delete-game-button") &&
                        !edit
                      ) {
                        navigate(`/${game.name.toLowerCase()}`);
                      }
                    }}
                  >
                    {edit && editGame?.id === game.id ? (
                      <>
                        <h2 className="form-title">
                          <strong>Название вида спорта:</strong>
                          <input
                            defaultValue={game.name}
                            className="game-input"
                            onChange={(e) =>
                              setEditGame({ ...editGame, name: e.target.value })
                            }
                          />
                        </h2>
                        <div className="game-item-actions">
                          <button
                            className="edit-game-button"
                            onClick={() => {
                              editGameDetails(editGame.id, editGame);
                              setEdit(false);
                              setEditGame(null);
                            }}
                          >
                            Сохранить
                          </button>
                          <button
                            className="edit-game-button"
                            onClick={() => {
                              setEdit(false);
                              setEditGame(null);
                            }}
                          >
                            Отменить
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <h2 className="game-title">{game.name}</h2>
                        {isLogged && (
                          <div className="game-item-actions">
                            <button
                              className="edit-game-button"
                              onClick={(e) => {
                                e.stopPropagation();
                                setEdit(true);
                                setEditGame(game);
                              }}
                            >
                              Изменить
                            </button>
                            <button
                              className="delete-game-button"
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteGame(game.id);
                              }}
                            >
                              Удалить
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ))}
                {isLogged && (
                  <div className="game-item game-form">
                    <h2 className="form-title">Добавить вид спорта</h2>
                    <input
                      id="gameName"
                      className="game-input"
                      placeholder="Название"
                    />
                    <button
                      className="add-game-button"
                      onClick={() => addGame()}
                    >
                      Добавить
                    </button>
                  </div>
                )}
              </div>
            </>
            ) : (
              <h2>Результаты не найдены</h2>
            )}
        </div>
    </div>
  );
};

export default Red;